import {environment} from "../../environments/environment";


function bind(endpoint:string):string{
  return `${environment.baseUrl}/${endpoint}`
}

const endpoints:any = {
  login :'login', //post
  getMyDetails:'get-my-details',//post
  reports:{
    homeReports : 'reports/home'//post
  },
  customers:{
    all:'customers/all'
  },
  distanceMatrix :{
    allRecords:'distance-matrix',
    add:'distance-matrix/new-destination',
    delete:'distance-matrix/delete-destination',
    update:'distance-matrix/update-destination'
  }
}

export const login:string = bind(endpoints.login)
export const getMyDetails:string = bind(endpoints.getMyDetails)
export const homeReports:string = bind(endpoints.reports.homeReports)
export const allCustomers:string = bind(endpoints.customers.all)
export const addNewDestination:string = bind(endpoints.distanceMatrix.add)
export const allRecords:string = bind(endpoints.distanceMatrix.allRecords)
export const deleteDestinationMatrix:string = bind(endpoints.distanceMatrix.delete)
export const updateDestinationMatrix:string = bind(endpoints.distanceMatrix.update)